import Alpine from "alpinejs";

declare global {
  interface Window {
    Alpine;
  }
}

window.Alpine = Alpine;

const pigFontStack = "serif";
const pigPrimaryColor = "#fceec8";
const pigSecondaryColor = "#513326";
const pigTertiaryColor = "#828661";
const pigTheme1Foreground = "#e8e8e8";
const pigTheme1Background = "#758e7f";
const pigTheme2Foreground = "#e8e8e8";
const pigTheme2Background = "#513326";
const pigFacebookUrl = "https://fb.me/ThePigRooms";
const pigFacebookIcon = "img/socials/facebook-513326.png";
const pigTwitterUrl = "https://twitter.com/The_Pig_Hotel";
const pigTwitterIcon = "img/socials/twitter-513326.png";
const pigInstagramUrl = "https://instagr.am/the_pig_hotels";
const pigInstagramIcon = "img/socials/instagram-513326.png";
const pigYouTubeIcon = "img/socials/youtube-513326.png";

const defaultBrand = {
  fontStack: pigFontStack,
  parent: "home-grown-hotels",
  id: "home-grown-hotels",
  name: "Home Grown Hotels",
  url: "https://www.thepighotel.com",
  cleanUrl: "www.thepighotel.com",
  address: "Home Grown Hotels Ltd<br>Clayhill, Beechen Lane<br>Lyndhurst<br>SO43 7DD",
  brandLogo: "img/brands/home-grown-hotels.png",
  logo: "img/brands/thepig-no-strap.png",
  primaryColor: pigPrimaryColor,
  secondaryColor: pigSecondaryColor,
  tertiaryColor: pigTertiaryColor,
  theme1Foreground: pigTheme1Foreground,
  theme1Background: pigTheme1Background,
  theme2Foreground: pigTheme2Foreground,
  theme2Background: pigTheme2Background,
  facebookUrl: pigFacebookUrl,
  facebookIcon: pigFacebookIcon,
  twitterUrl: pigTwitterUrl,
  twitterIcon: pigTwitterIcon,
  instagramUrl: pigInstagramUrl,
  instagramIcon: pigInstagramIcon,
  youTubeIcon: pigYouTubeIcon,
};

const allBrands = [
  defaultBrand,
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-brockenhurst",
    name: "THE PIG - Brockenhurst",
    url: "https://www.thepighotel.com/brockenhurst/",
    cleanUrl: "www.thepighotel.com/brockenhurst",
    address: "Beaulieu Road<br>Brockenhurst<br>Hampshire<br>SO42 7QL",
    brandLogo: "img/brands/thepig-mark.png",
    logo: "img/brands/thepig.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#edd8c5",
    theme3Foreground: "#513326",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-wall",
    name: "THE PIG - in the wall",
    url: "https://www.thepighotel.com/in-the-wall/",
    cleanUrl: "www.thepighotel.com/in-the-wall",
    address: "8 Western Esplanade<br>Southampton<br>SO14 2AZ",
    brandLogo: "img/brands/thepig-wall-mark.png",
    logo: "img/brands/thepig-wall.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#d9d8dd",
    theme3Foreground: "#513326",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-bath",
    name: "THE PIG - near Bath",
    url: "https://www.thepighotel.com/near-bath/",
    cleanUrl: "www.thepighotel.com/near-bath",
    address: "Hunstrete House<br>Hunstrete<br>Pensford<br>Bath<br>BS39 4NS",
    brandLogo: "img/brands/thepig-bath-mark.png",
    logo: "img/brands/thepig-bath.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#d9d8dd",
    theme3Foreground: "#513326",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-beach",
    name: "THE PIG - on the beach",
    url: "https://www.thepighotel.com/on-the-beach/",
    cleanUrl: "www.thepighotel.com/on-the-beach",
    address: "Manor Road<br>Studland<br>Dorset<br>BH19 3AU",
    brandLogo: "img/brands/thepig-beach-mark.png",
    logo: "img/brands/thepig-beach.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#d6e0ec",
    theme3Foreground: "#513326",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-combe",
    name: "THE PIG - at Combe",
    url: "https://www.thepighotel.com/at-combe/",
    cleanUrl: "www.thepighotel.com/at-combe",
    address: "Gittisham<br>Honiton<br>EX14 3AD",
    brandLogo: "img/brands/thepig-combe-mark.png",
    logo: "img/brands/thepig-combe.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#edd8c5",
    theme3Foreground: "#513326",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-bridge-place",
    name: "THE PIG - at Bridge Place",
    url: "https://www.thepighotel.com/at-bridge-place/",
    cleanUrl: "www.thepighotel.com/at-bridge-place",
    address: "Bourne Park Rd<br>Bridge<br>Canterbury<br>CT4 5BH",
    brandLogo: "img/brands/thepig-bridge-mark.png",
    logo: "img/brands/thepig-bridge.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#653e43",
    theme3Foreground: "#e8e8e8",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-harlyn-bay",
    name: "THE PIG - at Harlyn Bay",
    url: "https://www.thepighotel.com/at-harlyn-bay/",
    cleanUrl: "www.thepighotel.com/at-harlyn-bay",
    address: "Harlyn Bay<br>Padstow<br>Cornwall<br>PL28 8SQ",
    brandLogo: "img/brands/thepig-harlyn-mark.png",
    logo: "img/brands/thepig-harlyn.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#383d42",
    theme3Foreground: "#e8e8e8",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    fontStack: pigFontStack,
    parent: "home-grown-hotels",
    id: "pig-south-downs",
    name: "THE PIG - in the South Downs",
    url: "https://www.thepighotel.com/in-the-south-downs/",
    cleanUrl: "www.thepighotel.com/in-the-south-downs",
    address: "Madehurst<br>Arundel<br>West Sussex<br>BN18 0NL",
    brandLogo: "img/brands/thepig-south-downs-mark.png",
    logo: "img/brands/thepig-south-downs.png",
    primaryColor: pigPrimaryColor,
    secondaryColor: pigSecondaryColor,
    tertiaryColor: pigTertiaryColor,
    theme1Foreground: pigTheme1Foreground,
    theme1Background: pigTheme1Background,
    theme2Foreground: pigTheme2Foreground,
    theme2Background: pigTheme2Background,
    theme3Background: "#666b5c",
    theme3Foreground: "#e8e8e8",
    facebookUrl: pigFacebookUrl,
    facebookIcon: pigFacebookIcon,
    twitterUrl: pigTwitterUrl,
    twitterIcon: pigTwitterIcon,
    instagramUrl: pigInstagramUrl,
    instagramIcon: pigInstagramIcon,
    youTubeIcon: pigYouTubeIcon,
  },
  {
    enlargedLogo: true,
    fontStack: "sans-serif",
    parent: "smoked-uncut",
    id: "smoked-uncut",
    name: "Smoked & Uncut",
    url: "https://www.smokedanduncut.com",
    cleanUrl: "www.smokedanduncut.com",
    address: "Clayhill, Beechen Lane<br>Lyndhurst<br>SO43 7DD",
    brandLogo: "img/brands/smoked-and-uncut-brand.png",
    logo: "img/brands/smoked-and-uncut.png",
    removeLobsterHatchery: true,
    primaryColor: "#eb70a9",
    secondaryColor: "#3e2414",
    tertiaryColor: "#3e2414",
    theme1Foreground: "#ffffff",
    theme1Background: "#eb70a9",
    theme2Foreground: "#ffffff",
    theme2Background: "#3e2414",
    theme3Foreground: "#ffffff",
    theme3Background: "#218777",
    facebookUrl: "https://www.facebook.com/SmokedUncut/",
    facebookIcon: "img/socials/facebook-eb70a9.png",
    twitterUrl: "https://twitter.com/smokedanduncut",
    twitterIcon: "img/socials/twitter-eb70a9.png",
    instagramUrl: "https://www.instagram.com/smokedanduncut/",
    instagramIcon: "img/socials/instagram-eb70a9.png",
    youTubeUrl: "https://www.youtube.com/channel/UCGOahJv_K0gYnK0y4ljyMPw",
    youTubeIcon: "img/socials/youtube-eb70a9.png",
  },
];

Alpine.store("brands", {
  current: {
    defaultBrand,
  },
  items: allBrands,
});

Alpine.start();
